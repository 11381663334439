import { useEffect } from 'react';

/* global dataLayer */

const GoogleTagManager = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const script = document.createElement('script');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=G-ZRVRW195MX';
      script.async = true;
      document.head.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'G-ZRVRW195MX');

      return () => {
        // Cleanup script tag when the component is unmounted
        document.head.removeChild(script);
      };
    }
  }, []);

  return null;
};

export default GoogleTagManager;