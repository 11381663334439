import React from 'react';
import '../styles/footer.scss';
import Slogan from './Slogan';
import FooterMenuLinks from './FooterMenuLinks';

const Footer = () => {
  // Get the current year
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className='top'>
        <div className='max-content'>
          <Slogan />
        </div>
      </div>
      <div className='bottom'>
        <div className='max-content'>
          <FooterMenuLinks />
          <div className="copywrite">
            © {currentYear} 6ixTopSix Ltd.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
