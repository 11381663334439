import React from 'react';
import '../styles/header.scss';
import '../styles/skip-link.scss';
import SkipLink from './SkipLink';
import Slogan from './Slogan';

const Header = () => {
  return (
    <header>
      <div className='max-content'>
        <SkipLink />
        <Slogan />
      </div>
    </header>
  );
};

export default Header;
