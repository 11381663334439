import React from 'react';

const Contact = () => {
    return (
        <div className='contact'>
            <p>We value your feedback and inquiries. If you have any questions, suggestions, or concerns, please don't hesitate to reach out to us.</p>
            [CONTACT_FORM]
            <p>Our dedicated team is available to assist you and provide prompt responses to your messages. We look forward to hearing from you!</p>
        </div>
    );
};

export default Contact;