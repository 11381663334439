import React, { useEffect, useState } from 'react';
import '../styles/blog-post.scss';
import { useParams } from 'react-router-dom';
import { getBlogPosts, getCategories } from '../api';

const BlogPost = () => {
  const { categorySlug, postSlug } = useParams();
  const [blogPost, setBlogPost] = useState(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const categories = await getCategories();
        // All categories that are used by blogPosts
        setCategories(categories.data.sort((a, b) => a.attributes.name.localeCompare(b.attributes.name)));

        const blogPosts = await getBlogPosts();
        // Find the blog post with the matching category slug and post slug
        const matchedPost = blogPosts.data.find(
          (post) =>
            post.attributes.category.data.attributes.slug === categorySlug &&
            post.attributes.slug === postSlug
        );

        if (matchedPost) {
          setBlogPost(matchedPost);
        } else {
          console.error('Error fetching blog post: Post not found');
        }
      } catch (error) {
        console.error('Error fetching blog post:', error);
      }
    };

    fetchBlogPost();
  }, [categorySlug, postSlug]);

  return (
    <div className="blog-post">
      {blogPost && (
        <article>
          <h1 tabIndex={0}>{blogPost.attributes.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: blogPost.attributes.content }}></div>
        </article>
      )}
      <aside className='sidebar'>
          <article>
          <div className="cat_sidebar card">
             <h2 className='title'>Categories</h2>
              <ul className="sidebar_cat_list">
                  {categories.map((category) => (
                      <li key={category.id}>
                          <a href={`/${category.attributes.slug}`}>{category.attributes.name}</a>
                      </li>
                  ))}
              </ul>
          </div>
          </article>
      </aside>
    </div>
  );
};

export default BlogPost;
