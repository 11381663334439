import React from 'react';

const Privacy = () => {
    return (
        <div className='privacy'>
        <h1>Privacy Policy</h1>
        <p>This Privacy Policy describes how we collect, use, disclose, and protect your personal information when you use our website. We are committed to respecting and protecting your privacy in accordance with applicable privacy laws.</p>
      
        <h2>1. Information We Collect</h2>
        <p>We may collect the following types of personal information from you:</p>
        <ul>
          <li>Contact Information: When you interact with our website, you may voluntarily provide us with your name, email address, phone number, or other contact details.</li>
          <li>Usage Data: We may collect information about how you use our website, including your IP address, browser type, access times, and pages viewed.</li>
        </ul>
      
        <h2>2. Use of Personal Information</h2>
        <p>We may use your personal information for the following purposes:</p>
        <ul>
          <li>To provide and improve our services to you.</li>
          <li>To communicate with you and respond to your inquiries.</li>
          <li>To personalize your experience on our website.</li>
          <li>To analyze and understand how our website is used and to improve its functionality.</li>
        </ul>
      
        <h2>3. Disclosure of Personal Information</h2>
        <p>We may disclose your personal information in the following circumstances:</p>
        <ul>
          <li>Service Providers: We may share your personal information with trusted third-party service providers who assist us in operating our website and providing our services. These service providers are obligated to keep your information secure and confidential.</li>
          <li>Legal Requirements: We may disclose your personal information if required to do so by law or if we believe that such action is necessary to comply with a legal obligation or protect our rights, property, or safety, or the rights, property, or safety of others.</li>
        </ul>
      
        <h2>4. Data Retention</h2>
        <p>We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
      
        <h2>5. Security Measures</h2>
        <p>We employ reasonable security measures to protect your personal information from unauthorized access, use, or disclosure. However, please note that no method of transmission over the Internet or electronic storage is 100% secure.</p>
      
        <h2>6. Your Rights</h2>
        <p>You have the right to access, correct, update, or delete your personal information held by us. If you wish to exercise any of these rights or have any questions or concerns about our privacy practices, please contact us using the information provided below.</p>
      
        <h2>7. Contact Us</h2>
        <p>If you have any questions, comments, or concerns about this Privacy Policy or our privacy practices, please contact us.</p>
        </div>
    );
};

export default Privacy;