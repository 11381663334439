import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFooterMenuLinks } from '../api';

const FooterMenuLinks = () => {
    const [links, setLinks] = useState([]);

    useEffect(() => {
        const fetchLinks = async () => {
            try {
                const response = await getFooterMenuLinks();
                setLinks(response.data);
            } catch (error) {
                console.error('Error fetching footer menu links:', error);
            }
        };

        fetchLinks();
    }, []);

    return (
        <div className="links">
            {links.map((link) => (
                <Link key={link.id} to={link.attributes.hyperlink}>{link.attributes.text}</Link>
            ))}
        </div>
    );
};

export default FooterMenuLinks;
