import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Home from './components/Home';
import CategoryPage from './components/CategoryPage';
import Header from './components/Header';
import Footer from './components/Footer';
import BlogPost from './components/BlogPost';
import Contact from './components/Contact';
import Advertise from './components/Advertise';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import GoogleTagManager from './components/GoogleTagManager';

function App() {
  return (
    <Router>
      <ScrollToTop>
        <div className="App">
          <GoogleTagManager />
          <Header />
          <main className='main-content'>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/:slug" element={<CategoryPage />} />
              <Route path="/:categorySlug/:postSlug" element={<BlogPost />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/advertise" element={<Advertise />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
            </Routes>
          </main>
          <Footer />
        </div>
        </ScrollToTop>
    </Router>
  );
}

export default App;
