import React, { useEffect, useState } from 'react';
import '../styles/logo-slogan.scss';
import { Link } from 'react-router-dom';
import { getSlogan } from '../api';

const Slogan = () => {
    const [sloganText, setSloganText] = useState('');
    const [logoImg, setLogoImg] = useState('');

    useEffect(() => {
        const fetchSlogan = async () => {
            try {
                const sloganResponse = await getSlogan();

                const fetchedLogo = sloganResponse.data.attributes.logo;
                const fetchedSloganText = sloganResponse.data.attributes.slogan;

                setLogoImg(fetchedLogo);
                setSloganText(fetchedSloganText);
            } catch (error) {
                console.error('Error fetching slogan:', error);
            }
        };

        fetchSlogan();
    }, []);

    return (
        <div className='logo-slogan'>
            <Link to="/" className='logo' dangerouslySetInnerHTML={{ __html: logoImg }}></Link>
            <span className='slogan'>{sloganText}</span>
        </div>
    )
};

export default Slogan;
