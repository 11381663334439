import React from 'react';
import { useNavigate } from 'react-router-dom';

const SkipLink = () => {
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    const mainContent = document.querySelector('.main-content');
    const focusableElements = mainContent.querySelectorAll('button, a[href], input, select, textarea, [tabIndex="0"]');
    const firstFocusableElement = focusableElements[0];

    if (firstFocusableElement) {
      firstFocusableElement.focus();
    }

    navigate('#main-content');
  };

  return (
    <a href="#main-content" className="skip-link" onClick={handleClick}>
      Skip to content
    </a>
  );
};

export default SkipLink;