import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/home.scss';
import HeroHome from './HeroHome';
import { getBlogPosts, getCategories } from '../api';

const Home = () => {
  const [heroArticle, setHeroArticle] = useState(null);
  const [otherArticles, setOtherArticles] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const blogPosts = await getBlogPosts();
        const categories = await getCategories();

        // Reverse the order of the blog posts array
        const reversedBlogPosts = blogPosts.data.reverse();

        // Assuming the first post is the hero article
        setHeroArticle(reversedBlogPosts[0]);
        // Set the remaining reversedBlogPosts as other articles
        setOtherArticles(reversedBlogPosts.slice(1));
        // All categories that are used by blogPosts
        setCategories(categories.data.sort((a, b) => a.attributes.name.localeCompare(b.attributes.name)));
      } catch (error) {
        console.error('Error fetching reversedBlogPosts:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="home">
      {heroArticle && <HeroHome heroArticle={heroArticle} categories={categories} />}

      <section className="other-articles">
        {otherArticles.map((article) => (
          <article key={article.id}>
            <Link className='card' to={`${article.attributes.category.data.attributes.slug}/${article.attributes.slug}`}>
              <img src={article.attributes.thumbnailPath} alt={article.attributes.title} width={240} height={126} />
              {article.attributes.category.data && (
                  <p className="cat">{article.attributes.category.data.attributes.name}</p>
              )}
              <h2 className='title'>{article.attributes.title}</h2>
            </Link>
          </article>
        ))}
      </section>
    </div>
  );
};

export default Home;
