import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();

  const scrollToTop = () => {
    const scrollStep = window.scrollY / (300 / 15); // Calculate scroll step based on duration
    const scrollAnimation = () => {
      if (window.scrollY !== 0) {
        window.scrollBy(0, -scrollStep);
        requestAnimationFrame(scrollAnimation);
      }
    };
    requestAnimationFrame(scrollAnimation);
  };

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return children;
};

export default ScrollToTop;