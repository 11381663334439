import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/hero-home.scss';

const HeroHome = ({ heroArticle, categories }) => {
    return (
      <section className="hero">
        <article key={heroArticle.id}>
            <Link className='card' to={`${heroArticle.attributes.category.data.attributes.slug}/${heroArticle.attributes.slug}`}>
                <img src={heroArticle.attributes.thumbnailPath} alt={heroArticle.attributes.title} width={240} height={126} />
                {heroArticle.attributes.category.data && (
                    <p className="cat">{heroArticle.attributes.category.data.attributes.name}</p>
                )}
                <h1 className='title'>{heroArticle.attributes.title}</h1>
            </Link>
        </article>
        <aside className='sidebar'>
            <article>
            <div className="cat_sidebar card">
			   <h2 className='title'>Categories</h2>
                <ul className="sidebar_cat_list">
                    {categories.map((category) => (
                        <li key={category.id}>
                            <a href={category.attributes.slug}>{category.attributes.name}</a>
                        </li>
                    ))}
                </ul>
            </div>
            </article>
        </aside>
    </section>
    );
};

export default HeroHome;
