import axios from 'axios';

export const getBlogPosts = async () => {
  try {
    const response = await axios.get('http://localhost:1337/api/blog-posts?populate=category', {
      headers: {
        Authorization: 'Bearer ebc2a32cc597579d8cb1c3c40e661bb8a819316d518107f74fcf990f5b9b334a4051a617cc7743407ae4cfaf89f9d6b160d113b55ed518b126442c0811c3f26a1b3b220e5729c55d2515a9854fe9c4b1d8eaa65a1d5bc4b1cb32d599440bbb355c249d9ce244d5365c012f2f1bc96a05ca2d4d3c4d823e918daaaf29511e838d',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching blog posts:', error);
    return [];
  }
};

export const getCategories = async () => {
  try {
    const response = await axios.get('http://localhost:1337/api/categories', {
      headers: {
        Authorization: 'Bearer ebc2a32cc597579d8cb1c3c40e661bb8a819316d518107f74fcf990f5b9b334a4051a617cc7743407ae4cfaf89f9d6b160d113b55ed518b126442c0811c3f26a1b3b220e5729c55d2515a9854fe9c4b1d8eaa65a1d5bc4b1cb32d599440bbb355c249d9ce244d5365c012f2f1bc96a05ca2d4d3c4d823e918daaaf29511e838d',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error);
    return [];
  }
};

export const getSlogan = async () => {
  try {
    const response = await axios.get('http://localhost:1337/api/slogan', {
      headers: {
        Authorization: 'Bearer ebc2a32cc597579d8cb1c3c40e661bb8a819316d518107f74fcf990f5b9b334a4051a617cc7743407ae4cfaf89f9d6b160d113b55ed518b126442c0811c3f26a1b3b220e5729c55d2515a9854fe9c4b1d8eaa65a1d5bc4b1cb32d599440bbb355c249d9ce244d5365c012f2f1bc96a05ca2d4d3c4d823e918daaaf29511e838d',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching slogan:', error);
    return [];
  }
};

export const getFooterMenuLinks = async () => {
  try {
    const response = await axios.get('http://localhost:1337/api/footer-menu-links', {
      headers: {
        Authorization: 'Bearer ebc2a32cc597579d8cb1c3c40e661bb8a819316d518107f74fcf990f5b9b334a4051a617cc7743407ae4cfaf89f9d6b160d113b55ed518b126442c0811c3f26a1b3b220e5729c55d2515a9854fe9c4b1d8eaa65a1d5bc4b1cb32d599440bbb355c249d9ce244d5365c012f2f1bc96a05ca2d4d3c4d823e918daaaf29511e838d',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching FooterMenuLinks:', error);
    return [];
  }
};

export const filterForCategoryPage = async (categoryId) => {
  try {
    const response = await axios.get(`http://localhost:1337/api/categories/${categoryId}?populate=blog_posts`, {
      headers: {
        Authorization: 'Bearer ebc2a32cc597579d8cb1c3c40e661bb8a819316d518107f74fcf990f5b9b334a4051a617cc7743407ae4cfaf89f9d6b160d113b55ed518b126442c0811c3f26a1b3b220e5729c55d2515a9854fe9c4b1d8eaa65a1d5bc4b1cb32d599440bbb355c249d9ce244d5365c012f2f1bc96a05ca2d4d3c4d823e918daaaf29511e838d',
      },
    });
    // return `http://localhost:1337/api/categories/${categoryId}?populate=blog_posts`;
    return response.data;
  } catch (error) {
    console.error('Error fetching filterForCategoryPage:', error);
    return [];
  }
};
