import React from 'react';

const Terms = () => {
    return (
        <div className='terms'>
            <h1>Terms of Service</h1>
            <p>These Terms of Service ("Terms") govern your use of our website. Please read these Terms carefully before using our website. By accessing or using our website, you agree to be bound by these Terms.</p>
            <h2>1. Intellectual Property</h2>
            <p>All content on our website, including text, graphics, logos, images, audio clips, video clips, and software, is the property of our company or our licensors and is protected by Canadian and international copyright laws. You may not reproduce, modify, distribute, or republish any content from our website without our prior written consent.</p>
            <p>Some images used on our website may be obtained from third-party sources under a royalty-free license. These images are used in accordance with the terms and conditions of the respective licenses and remain the property of their respective owners.</p>
            <h2>2. Disclaimer of Liability</h2>
            <p>Our website and its content are provided on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, regarding the accuracy, reliability, or completeness of the content. We disclaim any liability for any errors or omissions in the content or for any loss or damage resulting from your use of the website.</p>
            <h2>3. User Conduct</h2>
            <p>When using our website, you agree to:</p>
            <ul>
                <li>Comply with all applicable laws and regulations.</li>
                <li>Use the website for lawful purposes only and not for any illegal or unauthorized activities.</li>
                <li>Respect the intellectual property rights of others.</li>
                <li>Not interfere with or disrupt the operation of the website.</li>
            </ul>
            <h2>4. Links to Third-Party Websites</h2>
            <p>Our website may contain links to third-party websites that are not owned or controlled by us. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites. We encourage you to read the terms and conditions and privacy policy of any third-party website that you visit.</p>
            <h2>5. Modification of Terms</h2>
            <p>We reserve the right to modify or update these Terms at any time without prior notice. It is your responsibility to review these Terms periodically for any changes. By continuing to use our website after any modifications or updates to these Terms, you agree to be bound by the revised Terms.
            </p>
            <h2>6. Governing Law and Jurisdiction</h2>
            <p>These Terms shall be governed by and construed in accordance with the laws of Canada. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of Canada.</p>
            <h2>7. Contact Us</h2>
            <p>If you have any questions, comments, or concerns about these Terms or our website, please contact us.</p>
        </div>
    );
};

export default Terms;