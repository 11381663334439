import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import '../styles/category.scss';
import { getCategories, filterForCategoryPage } from '../api';

const CategoryPage = () => {
  // Extract the category slug from the URL
  const { slug } = useParams();
  // Store the blog posts for the category
  const [blogPosts, setBlogPosts] = useState([]);
  // Using useRef to store the category name
  const categoryNameRef = useRef('');

  useEffect(() => {
    const fetchCategoryPosts = async () => {
      try {
        // Fetch all categories
        const categoriesResponse = await getCategories();
        const categories = categoriesResponse.data;
        // Find the category with the matching slug
        const category = categories.find((cat) => cat.attributes.slug === slug);

        if (category) {
          // Extract the category ID
          const categoryId = category.id;
          // Setting the category name using the useRef's current property
          categoryNameRef.current = category.attributes.name;
          // Fetch blog posts for the category using the category ID
          const response = await filterForCategoryPage(categoryId);
          const filteredPosts = response.data.attributes.blog_posts.data;
          // Set the blog posts in the state
          setBlogPosts(filteredPosts);
        } else {
          console.error('Error fetching category: Category not found');
        }
      } catch (error) {
        console.error('Error fetching category posts:', error);
      }
    };

    // Fetch category posts when the component mounts and when the slug changes
    fetchCategoryPosts();
  }, [slug]);

  return (
    <div className='category'>
      <h1>{categoryNameRef.current}</h1>
      <section className="other-articles">
        {blogPosts.map((article) => (
          <article key={article.id}>
            <Link className='card' to={`${article.attributes.slug}`}>
              <img src={article.attributes.thumbnailPath} alt={article.attributes.title} />
              <p className="cat">{categoryNameRef.current}</p>
              <h2 className='title'>{article.attributes.title}</h2>
            </Link>
          </article>
        ))}
      </section>
    </div>
  );
};

export default CategoryPage;
